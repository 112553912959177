<template>
    <div style="justify-content: center;">
        <slot />
    </div>
</template>
  
<script>


export default {

};
</script>
  