import Vue from "vue";
import Router from "vue-router";

import auth from "./auth";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Products from "./views/products-page";
import Customers from "./views/customers-page";
import Users from "./views/users-page";
import Categories from "./views/categories-page";
import Invoices from "./views/invoices-page";
import InvoicesDetails from "./views/invoices-details-page";
import InvoicesPayments from "./views/invoices-payments-page";
import WayBills from "./views/waybills-page";
import WayBillsDetails from "./views/waybills-details-page";
import InvoiceView from "./views/invoice-view";
import WayBillView from "./views/waybill-view";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import printLayout from "./layouts/print-layout"
import simpleLayout from "./layouts/single-card";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Home
      }
    },
    {
      path: "/profile",
      name: "profile",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Profile
      }
    },
    {
      path: "/categories",
      name: "categories",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Categories
      }
    },
    {
      path: "/products",
      name: "products",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Products
      }
    },
    {
      path: "/customers",
      name: "customers",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Customers
      }
    },
    {
      path: "/users",
      name: "users",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Users
      }
    },
    {
      path: "/invoices",
      name: "invoices",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Invoices
      }
    },
    {
      path: "/invoice-details",
      name: "invoice-details",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: InvoicesDetails
      }
    },
    {
      path: "/invoice-payments",
      name: "invoice-payments",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: InvoicesPayments
      }
    },
    {
      path: "/waybills",
      name: "waybills",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: WayBills
      }
    },
    {
      path: "/waybills-details",
      name: "waybills-details",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: WayBillsDetails
      }
    },
    {
      path: "/print-invoice/:id",
      name: "print-invoice",
      meta: { requiresAuth: true },
      components: {
        layout: printLayout,
        content: InvoiceView
      }
    },
    {
      path: "/print-waybill/:id",
      name: "print-waybill",
      meta: { requiresAuth: true },
      components: {
        layout: printLayout,
        content: WayBillView
      }
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/login-form")
      },
      props: {
        layout: {
          title: "Giriş Yap"
        }
      }
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "*",
      redirect: "/home"
    }
  ]
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "home" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
