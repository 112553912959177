<template>
  <div>
    <h2 class="content-block">Tahsilatlar</h2>
    <dx-data-grid ref="dataGrid" class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0"
      :show-borders="false" :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true" key="id">
      <dx-editing refresh-mode="full" :allow-adding="true" :allow-updating="true" :allow-deleting="true"
        :use-icons="false" :confirm-delete="true" mode="popup">
        <DxPopup :show-title="true" :width="700" :height="475" title="Tahsilat Detayları" />
        <DxForm>
          <DxItem :col-count="2" :col-span="2" item-type="group">
            <DxItem data-field="invoiceId" :col-span="2"/>
            <DxItem data-field="type" :col-span="2" />
            <DxItem data-field="date" />
            <DxItem data-field="price" />
            <DxItem data-field="note" :col-span="2" />
          </DxItem>
        </DxForm>
      </dx-editing>


      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column :visible="false" data-field="id" caption="#" :hiding-priority="2" :allow-editing="false" />

      <DxColumn data-field="invoiceId" caption="Proje">
        <DxLookup :data-source="invoicesData" value-expr="id" display-expr="project" />
      </DxColumn>

      <dx-column data-field="date" caption="Tarih" data-type="date" :hiding-priority="2" />
      <dx-column data-field="type" caption="Ödeme Tipi" :hiding-priority="0">

        <dx-lookup display-expr="name" value-expr="value" :data-source="paymentTypes" />

      </dx-column>
      <dx-column data-field="price" caption="Alınan Tutar" :hiding-priority="2" />
      <dx-column data-field="note" caption="Not" :hiding-priority="2" />
      <dx-toolbar :items="toolbarItems" />
    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxLookup,
  DxEditing,
  DxPopup,
  DxForm,
  DxToolbar,
} from "devextreme-vue/data-grid";

import { DxItem } from 'devextreme-vue/form';

import { createStore } from "devextreme-aspnet-data-nojquery";

const accessToken = localStorage.getItem("accessToken");
import appInfo from "../app-info";
const globalSettings = appInfo.baseUrl;
const dataSource = createStore({
  key: "id",
  loadUrl: `${globalSettings}Invoice/Payments`,
  insertUrl: `${globalSettings}Invoice/Payments`,
  updateUrl: `${globalSettings}Invoice/Payments`,
  deleteUrl: `${globalSettings}Invoice/Payments`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const invoicesData = createStore({
  key: "id",
  loadUrl: `${globalSettings}Invoice/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const paymentTypes = [
    { name: "Çek/Senet", value: 4 },
    { name: "Banka Transferi", value: 3 },
    { name: "Kredi Kartı", value: 2 },
    { name: "Nakit", value: 1 }
];

export default {
  data() {
    return {
      dataSource,
      invoicesData,
      toolbarItems: [
        {
          name: "addButton",
          widget: "dxButton",
          location: "after",
          options: {
            icon: "add",
            text: "Yeni Tahsilat Ekle",
            onClick: () => {
              this.$refs.dataGrid.instance.addRow();
            }
          }
        }
      ],
      paymentTypes
    };
  },
  methods: {

  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxLookup,
    DxEditing,
    DxPopup,
    DxForm,
    DxToolbar,
    DxItem,
  }
};
</script>
