<template>
  <div>
    <h2 class="content-block">Kullanıcılar</h2>

    <dx-data-grid ref="dataGrid" class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0"
      :show-borders="false" :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true" key="id">
      <dx-editing refresh-mode="full" :allow-adding="true" :allow-updating="true" :allow-deleting="true"
        :use-icons="false" :confirm-delete="true" mode="popup">
        <DxPopup :show-title="true" :width="700" :height="525" title="Müşteri Detayları" />
        <DxForm>
          <DxItem :col-count="2" :col-span="2" item-type="group">
            <DxItem data-field="firstName" />
            <DxItem data-field="lastName" />
            <DxItem data-field="email" />
            <DxItem data-field="password" />
            <DxItem data-field="isAdmin" />
          </DxItem>
        </DxForm>
      </dx-editing>

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />
      <dx-column data-field="id" caption="#" :width="90" :hiding-priority="2" :allow-editing="false" :visible="false" />
      <dx-column data-field="firstName" caption="İsim" />
      <dx-column data-field="lastName" caption="Soyisim" />
      <dx-column data-field="email" caption="Email" />
      <dx-column data-field="isAdmin" caption="Admin Yetkisi" />
      <dx-column data-field="password" caption="Şifre" :visible="false"/>

      <dx-toolbar :items="toolbarItems" />
    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxEditing,
  DxPopup,
  DxForm,
  DxToolbar,
} from "devextreme-vue/data-grid";
import { DxItem } from 'devextreme-vue/form';

const productTypes = [
  { name: "Hizmet", value: 3 },
  { name: "Servis", value: 2 },
  { name: "Ürün", value: 1 }
];

import { createStore } from "devextreme-aspnet-data-nojquery";

const accessToken = localStorage.getItem("accessToken");
import appInfo from "../app-info";
const globalSettings = appInfo.baseUrl;
const dataSource = createStore({
  key: "id",
  loadUrl: `${globalSettings}User/Read`,
  insertUrl: `${globalSettings}User/Create`,
  updateUrl: `${globalSettings}User/Update`,
  deleteUrl: `${globalSettings}User/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

export default {
  data() {
    return {
      productTypes,
      dataSource,
      salePriceFormat: {
        style: "currency",
        currency: "TRY",
        minimumFractionDigits: 2,
      },
      toolbarItems: [
        {
          name: "addButton",
          widget: "dxButton",
          location: "after",
          options: {
            icon: "add",
            text: "Yeni Kullanıcı Ekle",
            onClick: () => {
              this.$refs.dataGrid.instance.addRow();
            }
          }
        }
      ]
    };
  },
  created() {

  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxToolbar,
    DxItem,
  }
};
</script>
