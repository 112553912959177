<template>
    <div class="container">
        <div class="invoice" v-if="loading">
            <div class="row">
                <div class="col-8">
                    <img style="height: 60px; width: auto;" src="https://billing-api.app.benimligim.com/logo.png"
                        class="logo">
                </div>
                <div class="col-2">
                    <img style="height: 60px; width: auto;" src="https://billing-api.app.benimligim.com/iso9001.jpeg"
                        class="logo">
                </div>
                <div class="col-2">
                    <img style="height: 60px; width: auto;" src="https://billing-api.app.benimligim.com/iso14001.jpeg"
                        class="logo">
                </div>
            </div>
            <br><br><br>
            <div class="row">
                <div class="col-7">
                    <p>
                        <strong>Tarih : </strong>{{ invoice.date | moment }}
                        <!-- <strong> Son Tarih:</strong> {{ invoice.expDate | moment }} <br /> -->
                    </p>
                    <h5>{{ invoice.code }} </h5>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <p>
                        <strong>{{appInfo.company.name}}</strong><br>
                        {{appInfo.company.authorized}}<br>
                        {{appInfo.company.email}}<br>
                        {{appInfo.company.phone}}<br>
                        {{appInfo.company.address}}<br>
                    </p>
                </div>
                <div class="col-5">
                    <p>
                        <strong>{{ invoice.customer.name }}</strong><br>
                        {{ invoice.customer.authorized }}<br>
                        {{ invoice.customer.email }}<br>
                        {{ invoice.customer.phone }}<br>
                        {{ invoice.customer.address }}<br>
                        <strong>Proje Adı : </strong>{{ invoice.project }}<br>
                    </p>
                </div>
            </div>
            <br>
            <br>
            <h6 style="width: 100%;text-align: center;">Fiyat Teklifi</h6>
            <hr class="solid">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Resim</th>
                        <th>Kod</th>
                        <th>Ürün</th>
                        <th class="text-right">M2</th>
                        <th class="text-right">M2 Fiyat</th>
                        <th class="text-right">Toplam Fiyat</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="invProduct in invoice.invoiceProduct" :key="invProduct.id">
                        <td><img style="width: 100px;object-fit: fill;"
                                :src="`${appInfo.baseUrl}${invProduct.product.imageId}`"></td>
                        <td>{{ invProduct.product.code }}</td>
                        <td>{{ invProduct.product.name }}</td>
                        <td class="text-right">
                            {{ invProduct.m2 }} m2<br />
                            ( {{ invProduct.quentity }} Adet )
                        </td>
                        <td class="text-right">{{ invProduct.perPrice }} {{ invoice.currency }}</td>
                        <td class="text-right">{{ invProduct.totalPrice }} {{ invoice.currency }}</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <h6>Ek Hizmetler</h6>
            <hr class="solid">
            <br />
            <p>
                {{ invoice.additionalServices }}
            </p>
            <div class="row">
                <div class="col-8">
                </div>
                <div class="col-4">
                    <table class="table table-sm text-right">
                        <tr>
                            <td><strong>Toplam Tutar</strong></td>
                            <td class="text-right">{{ invoice.totalPrice }} {{ invoice.currency }}</td>
                        </tr>
                        <tr>
                            <td><strong>Ek Hizmetler</strong></td>
                            <td class="text-right">{{ invoice.servicePrice }} {{ invoice.currency }}</td>
                        </tr>
                        <tr>
                            <td><strong>KDV</strong></td>
                            <td class="text-right">{{ invoice.kdvPrice }} {{ invoice.currency }}</td>
                        </tr>
                        <tr>
                            <td><strong>Genel Toplam</strong></td>
                            <td class="text-right">{{ invoice.generalTotalPrice }} {{ invoice.currency }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <h6>Notlar:</h6>
            <p class="conditions">
                {{ invoice.note }}
            </p>
            <br>
            <p>
                SİZE ÖZEL HAZIRLANAN FİYAT TEKLİFİ 7 GÜN İÇİN GEÇERLİDİR.
            </p>
            <p>
                <strong>Teslim şekli:</strong>
                Siparişin onaylandığı tarih itibari ile teslim tarihi ../../.... Fabrika çıkışlıdır.<br/>ÜRÜNLERMİZ FABRİKA TESLİMLİDİR, NAKLİYE ALICIYA AİTTİR.
            </p>
            <p>
                <strong>Ödeme:</strong>
                Sipariş Onayinda Peşin olup banka hesabımıza Havale - Eft şeklinde yapılacaktır.<br />
                <strong>Banka Hesap Numaramız; Exen Panel Sanayi ve Ticaret Ltd Şti, <br /> EMLAK KATILIM BANKASI TR55 0021 1000
                    0006 6039
                    2000 01</strong>
            </p>
            <p>
                <strong> Uygulama Detayı :</strong>
                Uygulamarda uygulama ekibinin ULAŞIM,KONAKLAMA ve İAŞE giderleri müşteri tarafından karşılanır.
            </p>
            <p>
                Satın alma sürecinin tamamlanmasının ardından, uygulama programının oluşturulması için proje yönetimi
                departmanımıza bilgi aktarılacak ve uygulama sahanızın metrekare durumuna bağlı olarak teslim planlamaları
                yapılacaktır.
                Proje uygulama alanında ihtiyaç halinde ek konstrüksiyon yapımı ve iskele/manlift tedariği, projenin sahibi
                tarafından sağlanmalıdır.
            </p>
        </div>
        <div class="area" v-else>
            <h6>Ürünler</h6>
        </div>
    </div>
</template>
<script>
import axios from "axios";
const accessToken = localStorage.getItem("accessToken");
import moment from "moment";
import appInfo from "../app-info";
export default {
    data() {
        return {
            loading: false,
            invoice: {},
            appInfo
        };
    },
    created() {
        axios.get(`${appInfo.baseUrl}Invoice/Print/` + this.$route.params.id, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }

        })
            .then(response => {
                this.invoice = response.data;
                console.log(response.data);
                this.loading = true;
                setTimeout(() => {
                    window.print();
                }, 1000);
            }).catch(error => {
                console.log(error)
            })
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD.MM.YYYY');
        }
    }
}
</script>

<style scoped>
body {
    background: #ccc;
    padding: 30px;
}

.container {
    width: 21cm;
    min-height: 29.7cm;
}

.invoice {
    background: #fff;
    width: 100%;
    padding: 50px;
}

.logo {
    width: 5.5cm;
}

.document-type {
    text-align: right;
    color: #444;
}

.conditions {
    font-size: 0.7em;
    color: #666;
}

.bottom-page {
    font-size: 0.7em;
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }

    html {
        background: none;
        padding: 0;
    }

    .mn {
        box-shadow: none;
        margin: 0;
    }

    span:empty {
        display: none;
    }

    .add,
    .cut {
        display: none;
    }
}

@page {
    margin: 0;
}
</style>