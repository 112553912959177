<template>
  <div>
    <h2 class="content-block">Ürünler</h2>

    <dx-data-grid ref="dataGrid" class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0"
      :show-borders="false" :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true" key="id">
      <dx-editing refresh-mode="full" :allow-adding="true" :allow-updating="true" :allow-deleting="true"
        :use-icons="false" :confirm-delete="true" mode="popup">
        <DxPopup :show-title="true" :width="700" :height="525" title="Ürün Detayları" />
        <DxForm>
          <DxItem :col-count="2" :col-span="2" item-type="group">
            <DxItem data-field="order" />
            <DxItem data-field="code" />
            <DxItem data-field="hscode" />
            <DxItem data-field="name" />
            <DxItem data-field="width" />
            <DxItem data-field="height" />
            <DxItem data-field="thickness" />
            <DxItem data-field="weight" />
            <DxItem data-field="material" />
            <DxItem data-field="origin" />
            <DxItem data-field="m2" />
            <DxItem data-field="categoryId" />
            <DxItem data-field="imageId" />
            <DxItem data-field="description" :col-span="2" />
          </DxItem>
        </DxForm>
      </dx-editing>

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column data-field="id" caption="#" :hiding-priority="2" :allow-editing="false" :visible="false" />

      <dx-column data-field="imageId" caption="Resim" :width="150" cell-template="cellTemplate"
        edit-cell-template="editCellTemplate" />
      <dx-column data-field="order" caption="Sıra No" />
      <dx-column data-field="code" caption="Kod" />
      <dx-column data-field="hscode" caption="Hs Code" />
      <dx-column data-field="name" caption="Ad" />
      <dx-column data-field="description" caption="Teknik Özellikler" />
      <DxColumn data-field="categoryId" caption="Kategori">
        <DxLookup :data-source="categoriesData" value-expr="id" display-expr="name" />
      </DxColumn>
      <dx-column data-field="width" caption="En" data-type="number" />
      <dx-column data-field="height" caption="Boy" data-type="number" />
      <dx-column data-field="thickness" caption="Kalınlık" data-type="number" />
      <dx-column data-field="weight" caption="Ağırlık" data-type="number" />
      <dx-column data-field="origin" caption="Menşei" />
      <dx-column data-field="material" caption="Materyal" />
      <dx-column data-field="m2" caption="M2" data-type="number" />
      <template #cellTemplate="{ data }">
        <img style="width: 200px;object-fit: fill;" :src="`${appInfo.baseUrl}${data.value}`">
      </template>

      <template #editCellTemplate="{ data }">
        <div>
          <dx-file-uploader name="file" :multiple="false" accept="image/*" upload-mode="instantly"
            :upload-url="`${appInfo.baseUrl}Product/Upload`" :upload-headers="headers"
            @uploaded="(e) => onUploaded(data, e)" />
          <!-- <dx-button class="retryButton" text="Retry" :visible="retryButtonVisible" @click="onClick" /> -->
        </div>
      </template>

      <dx-toolbar :items="toolbarItems" />

    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxLookup,
  DxEditing,
  DxPopup,
  DxForm,
  DxToolbar,
} from "devextreme-vue/data-grid";
import { DxItem } from 'devextreme-vue/form';

import DxFileUploader from "devextreme-vue/file-uploader";

const productTypes = [
  { name: "Hizmet", value: 3 },
  { name: "Servis", value: 2 },
  { name: "Ürün", value: 1 }
];

import { createStore } from "devextreme-aspnet-data-nojquery";

const accessToken = localStorage.getItem("accessToken");
import appInfo from "../app-info";
const globalSettings = appInfo.baseUrl;
const dataSource = createStore({
  key: "id",
  loadUrl: `${globalSettings}Product/Read`,
  insertUrl: `${globalSettings}Product/Create`,
  updateUrl: `${globalSettings}Product/Update`,
  deleteUrl: `${globalSettings}Product/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const categoriesData = createStore({
  key: "id",
  loadUrl: `${globalSettings}Category/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

export default {
  data() {
    return {
      productTypes,
      dataSource,
      categoriesData,
      salePriceFormat: {
        style: "currency",
        currency: "TRY",
        minimumFractionDigits: 2,
      },
      appInfo,
      toolbarItems: [
        {
          name: "addButton",
          widget: "dxButton",
          location: "after",
          options: {
            icon: "add",
            text: "Yeni Ürün Ekle",
            onClick: () => {
              this.$refs.dataGrid.instance.addRow();
            }
          }
        }
      ],
      headers: {
        Authorization: "Bearer " + accessToken,
      }
    };
  },
  methods: {
    onUploaded(cellInfo, e) {
      const response = JSON.parse(e.request.response);
      console.log(response, e);
      cellInfo.setValue(response.id);
    }
  },
  created() {

  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxLookup,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxFileUploader,
    DxToolbar,
  }
};
</script>
