<template>
  <div>
    <h2 class="content-block">Proforma Ürün Ekle</h2>
    <dx-data-grid ref="dataGrid" class="dx-card wide-card" :data-source="dataSource" :focused-row-index="0"
      :show-borders="false" :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true" key="id">
      <dx-editing refresh-mode="full" :allow-adding="true" :allow-updating="true" :allow-deleting="true"
        :use-icons="false" :confirm-delete="true" mode="popup">
        <DxPopup :show-title="true" :width="700" :height="475" title="Proforma Detayları" />
        <DxForm>
          <DxItem :col-count="2" :col-span="2" item-type="group">
            <DxItem data-field="invoiceId" :col-span="2" />
            <DxItem data-field="productId" :col-span="2" />
            <DxItem data-field="perPrice" />
            <DxItem data-field="m2" />
            <DxItem data-field="discount" />
          </DxItem>
        </DxForm>
      </dx-editing>

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column :visible="false" data-field="id" caption="#" :allow-editing="false" />
      <DxColumn data-field="invoiceId" caption="Proje">
        <DxLookup :data-source="invoicesData" value-expr="id" display-expr="project" />
      </DxColumn>

      <DxColumn data-field="productId" caption="Ürün">
        <DxLookup :data-source="productsData" value-expr="id" display-expr="name" />
      </DxColumn>
      <dx-column data-field="quentity" caption="Adet" />
      <dx-column data-field="m2" caption="Toplam M2" />
      <dx-column data-field="discount" caption="İndirim Oranı" />
      <dx-column data-field="perPrice" caption="M2 Fiyat" />
      <dx-column data-field="totalPrice" caption="Toplam Fiyat" />
      <dx-column data-field="discoundPrice" caption="İndirim Turar" />
      <dx-column data-field="generalTotalPrice" caption="Satır Toplamı" />
      <dx-column type="buttons">
        <!-- <DxButton text="Yazdır" :on-click="sendClick" /> -->
        <DxButton name="edit" />
        <DxButton name="delete" />
      </dx-column>
      <dx-toolbar :items="toolbarItems" />
    </dx-data-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxLookup,
  DxEditing,
  DxPopup,
  DxForm,
  DxToolbar,
  DxButton
} from "devextreme-vue/data-grid";

import { DxItem } from 'devextreme-vue/form';

import { createStore } from "devextreme-aspnet-data-nojquery";

const accessToken = localStorage.getItem("accessToken");
import appInfo from "../app-info";
const globalSettings = appInfo.baseUrl;
const dataSource = createStore({
  key: "id",
  loadUrl: `${globalSettings}Invoice/Details`,
  insertUrl: `${globalSettings}Invoice/Details`,
  updateUrl: `${globalSettings}Invoice/Details`,
  deleteUrl: `${globalSettings}Invoice/Details`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const productsData = createStore({
  key: "id",
  loadUrl: `${globalSettings}Product/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const invoicesData = createStore({
  key: "id",
  loadUrl: `${globalSettings}Invoice/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

export default {
  data() {
    return {
      dataSource,
      productsData,
      invoicesData,
      toolbarItems: [
        {
          name: "addButton",
          widget: "dxButton",
          location: "after",
          options: {
            icon: "add",
            text: "Yeni Proforma Ekle",
            onClick: () => {
              this.$refs.dataGrid.instance.addRow();
            }
          }
        }
      ]
    };
  },
  methods: {
    sendClick(e) {
      const id = e.row.data.invoiceId;
      const routeData = this.$router.resolve({ name: "print-invoice", params: { id } });
      window.open(routeData.href, '_blank');
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxLookup,
    DxEditing,
    DxPopup,
    DxForm,
    DxToolbar,
    DxButton,
    DxItem,
  }
};
</script>
