<template>
    <div class="container">
        <div class="waybill" v-if="loading">
            <div class="row">
                <div class="col-7">
                    <img src="https://billing-api.app.benimligim.com/logo.png" class="logo">
                </div>
                <div class="col-5">
                    <h5 class="text-right">{{ waybill.code }}</h5>
                    <p class="text-right">
                        Tarih: <strong> {{ waybill.date | moment }}</strong>
                    </p>
                    <!-- <p class="text-right">
                        Son Tarih: <strong> {{ waybill.expDate | moment }}</strong>
                    </p> -->
                </div>
            </div>
            <br><br><br>
            <div>
                <strong> {{ waybill.invoice.project }} </strong>
            </div>
            <div class="row">
                <div class="col-7">
                    <p>
                        <strong>{{appInfo.company.name}}</strong><br>
                        {{appInfo.company.authorized}}<br>
                        {{appInfo.company.email}}<br>
                        {{appInfo.company.phone}}<br>
                        {{appInfo.company.address}}<br>
                    </p>
                </div>
                <div class="col-5">
                    <p>
                        <strong>{{ waybill.invoice.customer.name }}</strong><br>
                        {{ waybill.invoice.customer.authorized }}<br>
                        {{ waybill.invoice.customer.email }}<br>
                        {{ waybill.invoice.customer.phone }}<br>
                        {{ waybill.invoice.customer.address }}<br>
                    </p>
                </div>
            </div>
            <br>
            <br>
            <h6>Ürünler</h6>
            <hr class="solid">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Resim</th>
                        <th>Kod</th>
                        <th>Ürün</th>
                        <th class="text-right">Adet</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="invProduct in waybill.wayBillProducts" :key="invProduct.id">
                        <td><img style="width: 100px;object-fit: fill;"
                                :src="`${appInfo.baseUrl}${invProduct.product.imageId}`"></td>
                        <td>{{ invProduct.product.code }}</td>
                        <td>{{ invProduct.product.name }}</td>
                        <td class="text-right">
                            {{ invProduct.quentity }} Adet
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <h6>Ek Hizmetler</h6>
            <hr class="solid">
            <br />
            <p>
                {{ waybill.invoice.additionalServices }}
            </p>

            <h6>Araç Bilgisi:</h6>
            <p class="conditions">
                Sürücü Adı Soyadı : {{ waybill.driver }}<br>
                Sürücü TC : {{ waybill.driverIdentity }}<br>
                {{ waybill.note }}
            </p>
            <br>
            <br>
            <br>
            <br>
        </div>
        <div class="area" v-else>
            <h6>Ürünler</h6>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
const accessToken = localStorage.getItem("accessToken");
import appInfo from "../app-info";
export default {
    data() {
        return {
            loading: false,
            waybill: {},
            appInfo
        };
    },
    created() {
        axios.get(`${appInfo.baseUrl}Waybill/Print/` + this.$route.params.id, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => {
                this.waybill = response.data;
                console.log(response.data);
                this.loading = true;
                setTimeout(() => {
                    window.print();
                }, 1000);
            }).catch(error => {
                console.log(error)
            })
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD.MM.YYYY');
        }
    }
}
</script>

<style scoped>
body {
    background: #ccc;
    padding: 30px;
}

.container {
    width: 21cm;
    min-height: 29.7cm;
}

.waybill {
    background: #fff;
    width: 100%;
    padding: 50px;
}

.logo {
    width: 5.5cm;
}

.document-type {
    text-align: right;
    color: #444;
}

.conditions {
    font-size: 0.7em;
    color: #666;
}

.bottom-page {
    font-size: 0.7em;
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }

    html {
        background: none;
        padding: 0;
    }

    .mn {
        box-shadow: none;
        margin: 0;
    }

    span:empty {
        display: none;
    }

    .add,
    .cut {
        display: none;
    }
}

@page {
    margin: 0;
}
</style>