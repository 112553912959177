const defaultUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

const appInfo = require('./app-info');
const axios = require('axios');

export default {
  _user: defaultUser,
  loggedIn() {
    return !!this._user;
  },

  async logIn(email, password) {
    try {

      const response = await axios.post(`${appInfo.default.baseUrl}User/login`, {
        email: email,
        password: password
      });
      console.log(response);
      localStorage.setItem("user",JSON.stringify(response.data.user));
      localStorage.setItem("accessToken",response.data.accessToken);
      this._user = response.data.user;
      // Send request
      console.log(email, password);
      // this._user = { ...defaultUser, email };

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },

  async logOut() {
    this._user = null;
  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
